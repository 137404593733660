.about {
    margin: auto;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
    margin: 0 1rem;
}

.about-text p {
    margin-top: 30px;
}

.about-text h1 {
    padding: 0;
    line-height: 1;
    font-weight: 700;
    font-size: 3.5rem;
    background-image: linear-gradient(to right, rgb(199, 132, 8), red);
    background-clip: text;
    color: transparent;
}

.about-text p {
    color: rgb(231, 231, 231);
}

.about-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.row {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin: 2rem; */
}

.row h4 {
    color: rgb(236, 236, 236);
    font-weight: 700;
    font-size: 1rem;
}

.about-img li {
    width: 200px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-img li:hover {
    background: linear-gradient(
        to right,
        rgba(255, 166, 0, 0.623),
        rgba(255, 0, 0, 0.637)
    );
    border-radius: 10px;
}

.about-img img {
    width: 85px;
    margin: 1rem;
}

/* LIGHT THEME */

.light-about {
    margin: auto;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.light-about .about-text h1 {
    background-image: linear-gradient(to right, #7600ff, #4d00ff);
    background-clip: text;
    color: transparent;
}

.light-about .about-text p {
    color: rgb(44, 44, 44);
}
.light-about .about-img li:hover {
    background: linear-gradient(to right, #7700ff7e, #4c00ff9d);
    border-radius: 10px;
}

.light-about .row h4 {
    color: rgb(27, 27, 27);
}

@media (max-width: 1235px) {
    .about {
        flex-direction: column;
    }
    .about-text {
        width: 100%;
    }
    .about-text {
        justify-content: flex-start;
        align-items: center;
        width: 90%;
    }

    .about-img {
        width: 100%;
    }

    .row {
        width: 100%;
    }
}

@media (max-width: 1203px) {
    .about-text h1 {
        font-size: 3rem;
    }
}
@media (max-width: 870px) {
    .about {
        flex-direction: column;
    }
}

@media (max-width: 605px) {
    .about-text {
        margin-top: 3rem;
    }

    .row {
        flex-direction: column;
        align-items: center;
    }

    .about-text h1 {
        font-size: 2rem;
    }
}

@media (max-width: 390px) {
    .about-text h1 {
        font-size: 1.5rem;
    }
}
@media (max-width: 1235px) {
    .light-about {
        flex-direction: column;
    }
}
