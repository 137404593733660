.hide-menu {
    display: none;
}

.show-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    /* width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(2, 2, 2, 0.89);
}

.show-menu-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.show-menu-ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.show-menu a {
    color: #fff;
    text-decoration: none;
}
.show-menu a:hover {
    color: tomato;
}

.show-menu-li {
    margin: 2rem;
    list-style: none;
    font-size: 3rem;
    color: rgb(235, 235, 235);
    cursor: pointer;
}

.show-menu-li:hover {
    color: tomato;
}
